import request from '@/utils/request'
import Swal from "sweetalert2";

/**
 * Composition api for make a request (replacing this.$store.dispatch('http/[method]))
 * Will use in many component, be careful when want to change this function
 */
export default function useHttp () {
  const toastError = (messages) => {
    if(messages){
      Swal.fire({
        icon: "error",
        title: 'Request Failed',
        text: messages,
        showConfirmButton: true,
        timer: 2600,
        timerProgressBar: true
      })
    }
  }

  const $post = (payload = {}) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'POST',
        ...payload
      })
        .then(res => {
          if(res.status !== 'undefined' && res.status === 'failed' ) {
            toastError('Sorry, process failed.')
            return false
          }
          resolve(res)
        })
        .catch(({ message }) => {
          toastError(message)
          reject(message)
        })
    })
  }

  const $get = (payload = {}) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'GET',
        ...payload
      })
        .then(
          res => resolve(res))
        .catch(({ message }) => {
          toastError(message)
          reject(message)
        })
    })
  }

  const $put = (payload = {}) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'PUT',
        ...payload
      })
      .then(res => {
        if(res.status !== 'undefined' && res.status === 'failed' ) {
          toastError('Sorry, process failed.')
          return false
        }
        resolve(res)
      })
        .catch(({ message }) => {
          toastError(message)
          reject(message)
        })
    })
  }

  const $patch = (payload = {}) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'PATCH',
        ...payload
      })
        .then(res => resolve(res))
        .catch(({ message }) => {
          toastError(message)
          reject(message)
        })
    })
  }

  const $delete = (payload = {}) => {
    return new Promise((resolve, reject) => {
      request({
        method: 'DELETE',
        ...payload
      })
        .then(res => resolve(res))
        .catch(({ message }) => {
          toastError(message)
          reject(message)
        })
    })
  }

  return {
    $post,
    $get,
    $put,
    $patch,
    $delete
  }
}
