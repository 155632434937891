import { userService } from '../../helpers/fakebackend/user.service';
import router from '../../router/index'
import useHttp from '@/comp-functions/useHttp'

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });
        const { $post } = useHttp()
        $post({
            url: 'auth/login',
            data: {
                email: email,
                password: password
            }
        })
        .then ( function (users){
            if (users.data.token) {
                // if login details are valid return user details and fake jwt token
                let userdata = users.data.user;
                let user = {
                    id: userdata.id,
                    username: userdata.email,
                    fullname: userdata.usr_fullname,
                    companyname: '',
                    usertype: userdata.usertype_code,
                    customerid: userdata.customer_id,
                    token: users.data.token.token
                };
                if(userdata.usertype_code == 'UTYINL'){
                    user.companyname = 'AntarPort'
                }else if(userdata.usertype_code == 'UTYCUS' || userdata.usertype_code == 'UTYACS'){
                    user.companyname = userdata.customer.name
                }else if(userdata.usertype_code == 'UTYVEN'){
                    user.companyname = userdata.vendor.name
                }
                localStorage.setItem('user', JSON.stringify(user));
                commit('loginSuccess', user);
                router.push('/');
            }else {
                let error = 'These credentials do not match our records.';
                //handleResponse('These credentials do not match our records.');
                commit('loginFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        })
        .catch(() => {
            // console.log('error', e)
            let error = 'These credentials do not match our records.';
            //handleResponse(error);
            commit('loginFailure', error);
            dispatch('notification/error', error, { root: true });
        })
        
        // userService.login(email, password)
        //     .then(
        //         user => {
        //             console.log('s3');
        //             commit('loginSuccess', user);
        //             router.push('/');
        //         },
        //         error => {
        //             console.log('33');
        //             commit('loginFailure', error);
        //             dispatch('notification/error', error, { root: true });
        //         }
        //     );
    },
    // Logout the user
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    }
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};