import store from '@/state/store'

export default [
    {
        path: '/public/register',
        name: 'public-register-user',
        component: () => import('../views/pages/public/register')
    },
    {
        path: '/public/register-success',
        name: 'public-register-success',
        component: () => import('../views/pages/public/register-success'),
    },
    {
        path: '/billing/invoice/print',
        name: 'apps-billing-invoiceprint',
        meta: { authRequired: true },
        component: () => import('../views/pages/billing/invoiceprint')
    },
    {
        path: '/billing/report',
        name: 'apps-billingreport-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/billing/billingreport')
    },
    {
        path: '/billing/revenue',
        name: 'apps-revenue-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/billing/revenuereport')
    },
    {
        path: '/billing/invoice/adjustment',
        name: 'apps-billing-invoiceadjustment',
        props: true,
        meta: { authRequired: true },
        component: () => import('../views/pages/billing/invoiceadjustmentadd')
    },
    {
        path: '/billing/created/:inv_id',
        name: 'apps-inv-created',
        props: true,
        meta: { authRequired: true },
        component: () => import('../views/pages/billing/invoiceconfirmationsuccess')
    },
    {
        path: '/order/label/print',
        name: 'apps-order-labelprint',
        meta: { authRequired: true },
        component: () => import('../views/pages/bookingorder/smulabelprint')
    },
    {
        path: '/login',
        name: 'apps-login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/changepassword',
        name: 'apps-changepassword',
        component: () => import('../views/pages/account/changepassword'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout/',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    // {
    //     path: '/changepassword/',
    //     name: 'change Password',
    //     meta: {
    //         authRequired: true,
    //         beforeResolve(routeTo, routeFrom, next) {
    //             if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    //                 store.dispatch('auth/logOut')
    //             } else {
    //                 store.dispatch('authfack/logout')
    //             }
    //             const authRequiredOnPreviousRoute = routeFrom.matched.some(
    //                 (route) => route.push('/changepassword')
    //             )
    //             // Navigate back to previous page, or home as a fallback
    //             next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
    //         },
    //     },
    // },
    {
        path: '/',
        name: 'apps-home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    // {
    //     path: '/',
    //     name: 'apps-changepassword',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/dashboard/changepassword')
    // },
    {
        path: '/homecustomer',
        name: 'Home Customer',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/indexcustomer')
    },
    {
        path: '/home2',
        name: 'home 2',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index2')
    },
    {
        path: '/pages/starter',
        name: 'Starter Page',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/starter')
    },
    {
        path: '/executivedashboard/dashboardfinance',
        name: 'Port List',
        meta: { authRequired: true },
        component: () => import('../views/pages/executivedashboard/dashboardfinance')
    },
    {
        path: '/masterdata/port',
        name: 'appsmd-port-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/port/portlist')
    },
    {
        path: '/masterdata/port/add',
        name: 'appsmd-port-add',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/port/portadd')
    },
    {
        path: '/masterdata/customer/lists',
        name: 'appsmd-customer-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/customer/customerlist')
    },
    {
        path: '/masterdata/customer/add',
        name: 'appsmd-customer-add',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/customer/customeradd')
    },
    {
        path: '/masterdata/customer/edit',
        name: 'appsmd-customer-edit',
        meta: { authRequired: true },
        props : true,
        component: () => import('../views/pages/masterdata/customer/customeredit')
    },
    {
        path: '/masterdata/vendor/lists',
        name: 'appsmd-vendor-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/vendor/vendorlist')
    },
    {
        path: '/masterdata/vendorconfigprt/lists',
        name: 'appsmd-vendorcfg-listprt',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/vendorconfig/vendorlistconfigport')
    },
    {
        path: '/masterdata/vendorconfigaircraft/lists',
        name: 'appsmd-vendorcfg-listaircraft',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/vendorconfig/vendorlistconfigaircraft')
    },
    {
        path: '/billing/invoiceinternal/lists',
        name: 'apps-invoice-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/invoice/billinglist')
    },
    {
        path: '/billing/invoice/lists',
        name: 'Billing List',
        meta: { authRequired: true },
        component: () => import('../views/pages/invoice/billinglistcustomer')
    },
    {
        path: '/invoice/smu/lists',
        name: 'Stock Manifest List',
        meta: { authRequired: true },
        component: () => import('../views/pages/invoice/smulist')
    },
    {
        path: '/masterdata/vendor/add',
        name: 'appsmd-vendor-add',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/vendor/vendoradd')
    },
    {
        path: '/masterdata/vendor/edit',
        name: 'appsmd-vendor-edit',
        meta: { authRequired: true },
        props : true,
        component: () => import('../views/pages/masterdata/vendor/vendoredit')
    },
    {
        path: '/masterdata/vendor/detail/:id',
        name: 'appsmd-vendor-detail',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/vendor/vendoradd')
    },
    {
        path: '/masterdata/vendorflight/lists',
        name: 'Vendor Flight List',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/vendorflight/vendflightadd')
    },
    {
        path: '/masterdata/vendorschedule/lists',
        name: 'appsmd-schedule-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/vendorschedule/vendorschedulelist')
    },
    {
        path: '/masterdata/vendorschedule/upload',
        name: 'appsmd-schedule-upload',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/vendorschedule/vendorscheduleupload')
    },
    {
        path: '/masterdata/vendorschedule/detail',
        name: 'appsmd-schedule-detail',
        meta: { authRequired: true },
        props:true,
        component: () => import('../views/pages/masterdata/vendorschedule/vendorscheduledetail')
    },
    {
        path: '/masterdata/vendorschedule/vendorscheduleupdate',
        name: 'Update Flight Status',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/vendorschedule/vendorscheduleupdate')
    },
    {
        path: '/masterdata/vendorrouteprice/lists',
        name: 'appsmd-scheduleprice-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/vendorschedule/vendorschedulepricelist')
    },
    {
        path: '/masterdata/vendorrouteprice/upload',
        name: 'appsmd-scheduleprice-upload',
        meta: { authRequired: true },
        component: () => import('../views/pages/masterdata/vendorschedule/vendorschedulepriceupload')
    },
    {
        path: '/bookingorder/add',
        name: 'apps-order-createinput',
        props: true,
        meta: { authRequired: true },
        component: () => import('../views/pages/bookingorder/bookingorderadd')
    },
    {
        path: '/order/detail/:p_ordno',
        name: 'apps-order-detail',
        props: true,
        meta: { authRequired: true },
       component: () => import('../views/pages/bookingorder/orderdetail')
    },
    {
        path: '/bookingorder/addpreview/',
        name: 'apps-order-createpreview',
        // props: true,
        meta: { authRequired: true },
        component: () => import('../views/pages/bookingorder/bookingorderaddpreview')
    },
    {
        path: '/bookingorder/created/:inv_id',
        name: 'apps-ordercust-created',
        props: true,
        meta: { authRequired: true },
        component: () => import('../views/pages/bookingorder/orderconfirmationsuccess')
    },
    {
        path: '/report/order',
        name: 'apps-report-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/bookingorder/orderreport')
    },
    {
        path: '/bookingorder/project/add',
        name: 'Create Project Order',
        meta: { authRequired: true },
        component: () => import('../views/pages/projectorder/projectorderadd')
    },
    {
        path: '/bookingorder/project/preview',
        name: 'apps-order-project-createpreview',
        // props: true,
        meta: { authRequired: true },
        component: () => import('../views/pages/projectorder/projectorderaddpreview')
    },
    {
        path: '/bookingorder/searchflight',
        name: 'apps-order-searchflight',
        meta: { authRequired: true },
        component: () => import('../views/pages/bookingorder/bookingordersearchflight')
    },
    {
        path: '/bookingorder/searchflight/:origin/:destination/:departure_date',
        name: 'apps-order-searchflightparams',
        props: true,
        meta: { authRequired: true },
        component: () => import('../views/pages/bookingorder/bookingordersearchflight')
    },
    {
        path: '/order/lists',
        name: 'apps-ordercust-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/bookingorder/orderlistcustomer')
    },
    // {
    //     path: '/bookingorder/customerinvoice/crtinvoice',
    //     name: 'Input Invoice',
    //     meta: { authRequired: true },
    //     component: () => import('../views/pages/customerinvoice/customerinvoiceadd')
    // },
    {
        path: '/manageorder/inputsmuno',
        name: 'apps-manageorder-inputsmuno',
        props: true,
        meta: { authRequired: true },
        component: () => import('../views/pages/customerinvoice/customerinvoicedetailsmu')
    },
    {
        path: '/manageorder/lists',
        name: 'apps-manageorder-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/customerinvoice/customerinvoicelist')
    },
    {
        path: '/manageorder/verification',
        name: 'apps-manageorder-verification',
        props: true,
        meta: { authRequired: true },
        component: () => import('../views/pages/bookingorder/orderverification')
    },
    // {
    //     path: '/masterdata/vendorschedule/detail',
    //     name: 'appsmd-schedule-detail',
    //     meta: { authRequired: true },
    //     props:true,
    //     component: () => import('../views/pages/masterdata/vendorschedule/vendorscheduledetail')
    // },
    {
        path: '/paymentconfirmation/add',
        name: 'Payment Confirmation Add',
        meta: { authRequired: true },
        component: () => import('../views/pages/paymentconfirmation/paymentconfirmationadd'),
        props:true
    },
    {
        path: '/paymentreceipt/add',
        name: 'Payment Receipt List',
        meta: { authRequired: true },
        component: () => import('../views/pages/paymentreceipt/paymentreceiptlist')
    },
    {
        path: '/orderverification/add',
        name: 'Order Verification Add',
        meta: { authRequired: true },
        component: () => import('../views/pages/orderverification/orderverificationadd')
    },
    {
        path: '/incoming/add',
        name: 'Create incoming',
        meta: { authRequired: true },
        component: () => import('../views/pages/incoming/incomingadd')
    },
    {
        path: '/incoming/searchincoming',
        name: 'Create Booking incoming',
        meta: { authRequired: true },
        component: () => import('../views/pages/incoming/crtincoming')
    },
    {
        path: '/incoming/lists',
        name: 'Incoming List',
        meta: { authRequired: true },
        component: () => import('../views/pages/incoming/incominglist')
    },

    {
        path: '/handover/add',
        name: 'Create Handover',
        meta: { authRequired: true },
        component: () => import('../views/pages/handover/handoveradd')
    },
    {
        path: '/handover/lists',
        name: 'Handover List',
        meta: { authRequired: true },
        component: () => import('../views/pages/handover/handoverlist')
    },
    {
        path: '/monitoring/tracking',
        name: 'tracking',
        meta: { authRequired: true },
        component: () => import('../views/pages/tracking/tracking'),
        props: true,
    },
    {
        path: '/monitoring/updateschedulestatus',
        name: 'apps-upd-schedule',
        meta: { authRequired: true },
        component: () => import('../views/pages/tracking/updateschedulestatus'),
        props: true
    },
    {
        path: '/monitoring/updateschedulecapacity',
        name: 'apps-upd-capacity',
        meta: { authRequired: true },
        component: () => import('../views/pages/tracking/updateschedulecapacity'),
        props: true
    },
    {
        path: '/monitoring/updateorderstatus',
        name: 'Update Order Status',
        meta: { authRequired: true },
        component: () => import('../views/pages/tracking/updateorderstatus')
    },
    {
        path: '/users/lists',
        name: 'appsmd-user-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/userlist')
    },
    {
        path: '/users/userrolelists',
        name: 'appsmd-userrole-list',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/userrolelist')
    },
    {
        path: '/users/add',
        name: 'appsmd-user-add',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/useradd')
    },
    {
        path: '/users/edit',
        name: 'appsmd-user-edit',
        meta: { authRequired: true },
        props: true,
        component: () => import('../views/pages/users/useredit')
    },
    /*{
        path: '/monitoring/checkprice',
        name: 'Check Price',
        meta: { authRequired: true },
        component: () => import('../views/pages/monitoring/checkprice')
    },*/
    {
        path: '/chat',
        name: 'Chat',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/chat/index')
    },
    {
        path: '/calendar',
        name: 'Calendar',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/calendar/index')
    },
    {
        path: '/ecommerce/products',
        name: 'products',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/products')
    },
    {
        path: '/ecommerce/product-detail',
        name: 'product detail',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/product-detail')
    },
    {
        path: '/ecommerce/orders',
        name: 'Orders',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/orders')
    },
    {
        path: '/ecommerce/customers',
        name: 'Customers',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/customers')
    },
    {
        path: '/ecommerce/cart',
        name: 'Cart',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/cart')
    },
    {
        path: '/ecommerce/checkout',
        name: 'Checkout',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/checkout')
    },
    {
        path: '/ecommerce/shops',
        name: 'Shops',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/shops')
    },
    {
        path: '/ecommerce/add-product',
        name: 'Add-product',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/add-product')
    },
    {
        path: '/email/inbox',
        name: 'Inbox',
        meta: { authRequired: true },
        component: () => import('../views/pages/email/inbox')
    },
    {
        path: '/email/read',
        name: 'Read Email',
        meta: { authRequired: true },
        component: () => import('../views/pages/email/reademail')
    },
    {
        path: '/auth/login-1',
        name: 'login-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/login-1')
    },
    {
        path: '/auth/register-1',
        name: 'register-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/register-1')
    },
    {
        path: '/auth/lock-screen-1',
        name: 'Lock-screen-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/lock-screen-1')
    },
    {
        path: '/auth/recoverpwd-1',
        name: 'Recoverpwd-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/recoverpwd-1')
    },
    {
        path: '/apps/kanban-board',
        name: 'Kanban-board',
        meta: { authRequired: true },
        component: () => import('../views/pages/kanbanboard/index')
    },
    {
        path: '/pages/maintenance',
        name: 'Maintenance',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/maintenance')
    },
    {
        path: '/pages/coming-soon',
        name: 'Coming-soon',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/coming-soon')
    },
    {
        path: '/pages/timeline',
        name: 'Timeline',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/timeline/index')
    },
    {
        path: '/pages/faqs',
        name: 'Faqs',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/faqs')
    },
    {
        path: '/pages/pricing',
        name: 'Pricing',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/pricing/index')
    },
    {
        path: '/pages/error-404',
        name: 'Error-404',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/error-404')
    },
    {
        path: '/pages/error-500',
        name: 'Error-500',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/error-500')
    },
    {
        path: '/icons/font-awesome',
        name: 'Font Awesome 5',
        meta: { authRequired: true },
        component: () => import('../views/pages/icons/font-awesome/index')
    },
    {
        path: '/icons/dripicons',
        name: 'Dripicons',
        meta: { authRequired: true },
        component: () => import('../views/pages/icons/dripicons')
    },
    {
        path: '/icons/material-design',
        name: 'Material Design',
        meta: { authRequired: true },
        component: () => import('../views/pages/icons/materialdesign/index')
    },
    {
        path: '/icons/remix',
        name: 'Remix Icons',
        meta: { authRequired: true },
        component: () => import('../views/pages/icons/remix/index')
    },
    {
        path: '/ui/buttons',
        name: 'Buttons',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/buttons')
    },
    {
        path: '/ui/alerts',
        name: 'Alerts',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/alerts')
    },
    {
        path: '/ui/grid',
        name: 'Grid',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/grid')
    },
    {
        path: '/ui/cards',
        name: 'Cards',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/cards')
    },
    {
        path: '/ui/carousel',
        name: 'Carousel',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/carousel')
    },
    {
        path: '/ui/dropdowns',
        name: 'Dropdowns',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/dropdowns')
    },
    {
        path: '/ui/images',
        name: 'Images',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/images')
    },
    {
        path: '/ui/modals',
        name: 'Modals',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/modals')
    },
    {
        path: '/ui/rangeslider',
        name: 'Range - slider',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/rangeslider')
    },
    {
        path: '/ui/progressbar',
        name: 'Progressbar',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/progressbars')
    },
    {
        path: '/ui/sweet-alert',
        name: 'Sweet-alert',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/sweet-alert')
    },
    {
        path: '/ui/tabs-accordion',
        name: 'Tabs & Accordion',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/tabs-accordions')
    },
    {
        path: '/ui/typography',
        name: 'Typography',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/typography')
    },
    {
        path: '/ui/video',
        name: 'Video',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/video')
    },
    {
        path: '/ui/general',
        name: 'General',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/general')
    },
    {
        path: '/ui/lightbox',
        name: 'Lightbox',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/lightbox')
    },
    {
        path: '/ui/notification',
        name: 'Notification',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/notification')
    },
    {
        path: '/ui/rating',
        name: 'Rating',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/rating')
    },
    {
        path: '/ui/session-timeout',
        name: 'Session Timeout',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/session-timeout')
    },
    {
        path: '/form/elements',
        name: 'Form Elements',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/elements')
    },
    {
        path: '/form/validation',
        name: 'Form validation',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/validation')
    },
    {
        path: '/form/advanced',
        name: 'Form Advanced',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/advanced')
    },
    {
        path: '/form/editor',
        name: 'CK Editor',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/ckeditor')
    },
    {
        path: '/form/uploads',
        name: 'File Uploads',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/uploads')
    },
    {
        path: '/form/wizard',
        name: 'Form Wizard',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/wizard')
    },
    {
        path: '/form/mask',
        name: 'Form Mask',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/mask')
    },
    {
        path: '/tables/basic',
        name: 'Basic Tables',
        meta: { authRequired: true },
        component: () => import('../views/pages/tables/basictable')
    },
    {
        path: '/tables/advanced',
        name: 'Advanced Tables',
        meta: { authRequired: true },
        component: () => import('../views/pages/tables/advancedtable')
    },
    {
        path: '/charts/apex',
        name: 'Apex chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/apex')
    },
    {
        path: '/charts/chartjs',
        name: 'Chartjs chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/chartjs/index')
    },
    {
        path: '/charts/chartist',
        name: 'Chartist chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/chartist')
    },
    {
        path: '/charts/echart',
        name: 'Echart chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/echart/index')
    },
    {
        path: '/maps/google',
        name: 'Google Maps',
        meta: { authRequired: true },
        component: () => import('../views/pages/maps/google')
    },
    {
        path: '/maps/leaflet',
        name: 'Leaflet Maps',
        meta: { authRequired: true },
        component: () => import('../views/pages/maps/leaflet/index')
    },
]
