/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
// axios
import axios from 'axios'
import { mapKeys, snakeCase } from 'lodash'
import { getToken, setToken } from '@/utils/auth'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: 70000 // Request timeout in second (60s)
})

// Request intercepter
axiosIns.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}` // Set JWT token
    }

    if (!(config.data instanceof FormData)) {
      // ex: accountNumber => account_number
      config.data = mapKeys(config.data, (val, key) => snakeCase(key))
      if (config.data.hasOwnProperty('details')) {
        config.data.details = config.data.details.map(prop => mapKeys(prop, (val, key) => snakeCase(key)))
      }
    }

    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response pre-processing
axiosIns.interceptors.response.use(
  response => {
    if (response.headers.authorization) {
      setToken(response.headers.authorization)
      response.data.token = response.headers.authorization
    }
    return response.data
  },
  error => {
    const message = !error.response ? 'Error: Network Error' : error.response.data.message
    return Promise.reject({ error, message })
  }
)

export default axiosIns
